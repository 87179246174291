.fuzzi-card-list .list {
    padding-top: 10px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: row;
    z-index: -10;
}


/* .fuzzi-card-list {
    overflow-x: scroll;
} */