.world-jar {
    display: flex;
    justify-content: space-evenly;
    position: relative;
    max-width: 1400px;
    margin: auto;
    padding-top: 50px;
    padding-bottom: 50px;
}

.world-jar .info-div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.world-jar .image-div {
    
}

.world-jar .image-div .image {
    width: 25vw;
    max-width: 500px;
    margin-top: auto;
    margin-bottom: auto;
}

.world-jar .header {
    color: white;
}

.world-jar .info-div button {
    height: 60px;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
}

.world-jar .buttons {
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.centerContent {
    /* border: 2px solid green; */
    width: 500px;
}

.world-jar .get-started {
    color: white;
    height: 5.5vw;
    width: 21vw;
    max-height: 75px;
    max-width: 300px;
    display: block;
    margin-top: 50px;
    text-transform: none;
}



@media only screen and (max-width: 900px) {
    .world-jar .buttons {
        height: 150px;
    }
    .world-jar .image-div .image {
        width: 40vw;
    }
    .world-jar .info-div button {
        height: 40px;
        width: 160px;
    }
    .world-jar .get-started {
        padding-top: 14px;
        height: 45px;
        width: 200px;
    }
}