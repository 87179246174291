.fuzzi {
    margin-bottom: 10px;
}

.fuzzi .fuzzi-container {
    border: 2px solid black;
    /* width: 100%; */
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
    position: relative;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.fuzzi .unopened-fuzzi {
    /* background: var(--primary); */   
    background-color: #ff7079;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.4'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");border: 2px solid black;
    height: 100px;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.fuzzi .fuzzi-prof-pic {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    margin-right: 5px;
}


.fuzzi .fuzzi-media-div {
    margin-top: 20px;
    color: white;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.fuzzi-media {
    margin-left: auto;
    margin-right: auto;
    width: 100% !important;
}

.fuzzi .fuzzi-title {
    margin-left: 20px;
    margin-right: 20px;
}

.fuzzi .fuzzi-author {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 10px;
    left: 10px;
}

.fuzzi .fuzzi-footer {
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
}

.fuzzi .fuzzi-footer .fuzzi-message {
    white-space: 'pre-line';
    text-align: 'center';

}


.fuzzi .delete {
    position: absolute;
    top: 0;
    right: 0;
}

/* MOBILE MEDIA */
@media only screen and (max-width: 1200px) {

    .fuzzi-media-url {
        width: 100% !important;
    }
}