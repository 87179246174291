@media only screen and (min-width: 1400px) {
    div .MuiTypography-h1 {
        font-size: 75px;
    }
    div .MuiTypography-h2 {
        font-size: 55px;
    }
    div .MuiTypography-h3 {
        font-size: 45px;
    }

    div .MuiTypography-h4 {
        font-size: 25px;
    }

    div .MuiTypography-h5 {
        font-size: 20px;
    }
    div .MuiTypography-h6 {
        font-size: 16px;
    }
}

@media only screen and (max-width: 900px) {
    div .MuiTypography-h1 {
        font-size: 8vw;
    }
    div .MuiTypography-h2 {
        font-size: 7vw;
    }
    div .MuiTypography-h3 {
        font-size: 5.5vw;
    }

    div .MuiTypography-h4 {
        font-size: 5vw;
    }

    div .MuiTypography-h5 {
        font-size: 4vw;
    }
    div .MuiTypography-h6 {
        font-size: 3.5vw;
    }
    div .MuiTypography-body1 {
        font-size: 3.5vw;
    }
    div .MuiTypography-body2 {
        font-size: 3vw;
    }

    div .MuiButton-label {
        font-size: 10px;
        white-space: nowrap;
    }
}

