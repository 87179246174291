@import url(https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F7F7F7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
} */

.MuiButton-root {
  border-radius: 8px !important;
}

body {
  margin: 0;
}

.App {
  text-align: center;
  font-family: Roboto;
}

/* dark mode text */
/* .App h1, h2, h3, p, div, label {
  color: #FFFF;
} */


  body {
    /* overflow-x: hidden; */
  }

.home {
  position: relative;
  overflow-x: hidden;
}

.home .particles {
  position: absolute;
  width: 1400px;
  height: 800px;
}



.home .gradient-angle {
  background: var(--homeGradient);
  height: 2000px;
  width: 140%;
  position: absolute;
  transform: rotate(-10deg);
  left: -20%;
  top: -1400px;
  z-index: -1;
  margin: auto;
}

.home .content {
  max-width: 1400px;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
}


@media only screen and (max-width: 900px) {
  .home .gradient-angle {
    width: 200%;
    top: -1400px;
    left: -100%;
  }
}


.home-section-1 {
    display: flex;
    /* flex-direction: row; */
    /* border: 2px solid yellow; */
    justify-content: space-evenly;
    padding-bottom: 50px;
}

.home-section-1 .image-div {
    /* border: 2px solid green; */
}

.home-section-1 .image-div .image {
    width: 30vw;
    max-width: 500px;
}

.home-section-1 .info-div .image {
    width: 40vw;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.home-section-1 .info-div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}


@media only screen and (max-width: 900px) {
    .home-section-1 .image-div .image {
        width: 50vw;
    }

    .home-section-1 .info-div .image {
        width: 60vw;
    }
}
.home-section-2 {
    position: relative;
}


.home-section-2 .background {
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 500000px;
    left: -40000px;
    background: var(--homeBackgroundSecondary);
}


.fuzzi-jar-manager {
    height: 100%;
}

.fuzzi-jar-manager .container {
    background: var(--cardBackgroundPrimary);
    position: relative;
    height: calc(100vh - 120px);
    overflow: hidden;
}

.fuzzi-jar-manager .back-button {
    position: absolute;
    left: 0;
    top: 0;
}


.fuzzi-jar-manager .content {
    width: 90%;
    max-height: 85%;
    margin: auto;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
}

.fuzzi-jar-manager .title-div {
    flex-grow: 0;
}

.fuzzi-jar-manager .title-flex {
    display: flex;
    flex-direction: row;
}

.fuzzi-jar-manager .divider {
    height: 3px;
    background: black;
}

.fuzzi-jar-manager .footer {
    background: var(--cardBackgroundSecondary);
    height: 80px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fuzzi-jar-manager .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
}

.fuzzi-jar-manager .delete {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px;
}

.fuzzi-jar-manager .jar {
    height: 100px;
}

.fuzzi-jar-manager .fuzzi-button {
    width: 175px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
}

.fuzzi-jar-manager .info-div {
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    justify-content: space-evenly;
    position: absolute;
    right: 0;
    top: 20px
}

.fuzzi-jar-manager .users-info {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.fuzzi-jar-manager .prof-pic {
    height: 50px;
    border-radius: 50px;
    margin-right: 10px;
}

.fuzzi-jar-manager .recipient {
    display: flex;
    flex-direction: row;
}

.fuzzi-jar-manager .vertical-divider {
    width: 1px;
    height: 40px;
    background: black;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    margin-right: 10px;
}

.fuzzi-jar-manager .collaborators {
    display: flex;
    flex-direction: row;
}   

.fuzzi-jar-manager .finish-and-send {
    height: 50px;
    width: 150px;
}



.fuzzi-jar-manager .messages {
    flex-grow: 2;
    overflow-y: scroll;
    padding-top: 10px;
    padding-bottom: 20px;
}


.fuzzi-jar-manager .footer-text {
    margin-top: auto !important;
    margin-bottom: auto;
}


/* MOBILE */

.fuzzi-jar-manager-mobile .container {
    background: var(--cardBackgroundPrimary);
    height: calc(100vh - 60px);
    width: 99vw;
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.fuzzi-jar-manager-mobile .header {
    padding: 10px;
    border-bottom: 2px solid black;
    flex-grow: 0;
}

.fuzzi-jar-manager-mobile .subheader {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.fuzzi-jar-manager-mobile .subheader {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 10px;
}

.fuzzi-jar-manager-mobile .subheader-info {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.fuzzi-jar-manager-mobile .fuzzis-opened {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fuzzi-jar-manager-mobile .jar {
    height: 80px;
}

.fuzzi-jar-manager-mobile .fuzzi-button {
    width: 125px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
}

.is-jar-manager-add-to-jar {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
}

.fuzzi-jar-manager-mobile .content {
    padding: 20px;
    /* background: blue; */
    flex-grow: 2;
    overflow-y: scroll;
}

.fuzzi-jar-manager-mobile .footer {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    background: var(--cardBackgroundSecondary);
    width: 100%;
    min-height: 45px;
    display: flex;
    flex-direction: row;
    /* border: 2px solid green; */
}

.fuzzi-jar-manager-mobile .recipient {
    display: flex;
    flex-direction: row;
}

.fuzzi-jar-manager-mobile .vertical-divider {
    width: 1px;
    height: 30px;
    background: black;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    margin-right: 10px;
}

.fuzzi-jar-manager-mobile .prof-pic {
    margin-top: auto;
    margin-bottom: auto;
    height: 40px;
    width: 40px;
    margin-right: 5px;
    border-radius: 50px;
}
.world-jar {
    display: flex;
    justify-content: space-evenly;
    position: relative;
    max-width: 1400px;
    margin: auto;
    padding-top: 50px;
    padding-bottom: 50px;
}

.world-jar .info-div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.world-jar .image-div {
    
}

.world-jar .image-div .image {
    width: 25vw;
    max-width: 500px;
    margin-top: auto;
    margin-bottom: auto;
}

.world-jar .header {
    color: white;
}

.world-jar .info-div button {
    height: 60px;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
}

.world-jar .buttons {
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.centerContent {
    /* border: 2px solid green; */
    width: 500px;
}

.world-jar .get-started {
    color: white;
    height: 5.5vw;
    width: 21vw;
    max-height: 75px;
    max-width: 300px;
    display: block;
    margin-top: 50px;
    text-transform: none;
}



@media only screen and (max-width: 900px) {
    .world-jar .buttons {
        height: 150px;
    }
    .world-jar .image-div .image {
        width: 40vw;
    }
    .world-jar .info-div button {
        height: 40px;
        width: 160px;
    }
    .world-jar .get-started {
        padding-top: 14px;
        height: 45px;
        width: 200px;
    }
}
.fuzzi-animation {
    background: rgba(0,0,0,0.9);
    z-index: 10000;

    /* covers full screen */
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    /* border: 3px solid yellow; */
    overflow: scroll;

    /* contained to card */
    /* position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; */

}

.fuzzi-animation .animated-fuzzi {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
}

.fuzzi-animation .particles {
    /* position: absolute; */
    height: 100vh;
    width: 100vw;
}

.fuzzi-animation .animation {
    /* position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0); */
}

.fuzzi-animation .particle-grow {
    height: 100vh;
    width: 100vw;
    /* border: 3px solid blue */
}

.fuzzi-animation .fuzzi-grow {
    position: relative;
    margin: auto;
    min-width: 40vw;
    /* border: 3px solid green; */
}

.fuzzi-animation .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border: 3px solid red; */
}

.fuzzi-animation .confirm-fuzzi {
    height: 80px;
    width: 250px;
    margin-top: 20px;
    /* margin-left: auto;
    margin-right: auto; */
}

.fuzzi-animation .call-to-action {
    margin-top: 20px;
}

/* MOBILE MEDIA */
@media only screen and (max-width: 1200px) {
    .fuzzi-animation .fuzzi-grow {
        min-width: 80vw;
    }
    .fuzzi-animation .confirm-fuzzi {
        height: 40px;
        width: 180px;
    }
}
.fuzzi {
    margin-bottom: 10px;
}

.fuzzi .fuzzi-container {
    border: 2px solid black;
    /* width: 100%; */
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
    position: relative;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.fuzzi .unopened-fuzzi {
    /* background: var(--primary); */   
    background-color: #ff7079;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.4'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");border: 2px solid black;
    height: 100px;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.fuzzi .fuzzi-prof-pic {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    margin-right: 5px;
}


.fuzzi .fuzzi-media-div {
    margin-top: 20px;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.fuzzi-media {
    margin-left: auto;
    margin-right: auto;
    width: 100% !important;
}

.fuzzi .fuzzi-title {
    margin-left: 20px;
    margin-right: 20px;
}

.fuzzi .fuzzi-author {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 10px;
    left: 10px;
}

.fuzzi .fuzzi-footer {
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
}

.fuzzi .fuzzi-footer .fuzzi-message {
    white-space: 'pre-line';
    text-align: 'center';

}


.fuzzi .delete {
    position: absolute;
    top: 0;
    right: 0;
}

/* MOBILE MEDIA */
@media only screen and (max-width: 1200px) {

    .fuzzi-media-url {
        width: 100% !important;
    }
}
.home-section-3 {
    display: flex;
    /* border: 2px solid yellow; */
    width: 100%;
    justify-content: space-evenly;
    padding-top: 150px;
}

.home-section-3 .image-div {
    /* border: 2px solid green; */
    padding-top: 50px;
}

.home-section-3 .info-div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.home-section-3 .image-div .image {
    /* border: 5px solid orange; */
    width: 30vw;
    max-width: 550px;
    margin: auto;
}

.home-section-3 .info-div .image {
    width: 35vw;
    max-width: 600px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.home-section-3 .info-div .text {
    margin-top: 10px;
    margin-bottom: 10px;
}


@media only screen and (max-width: 900px) {
    .home-section-3 .image-div .image {
        width: 50vw;
    }

    .home-section-3 .info-div .image {
        width: 60vw;
    }

    .home-section-3 {
        padding-top: 100px;
    }
}
.home-section-4 {
    display: flex;
    margin-top: 150px;
    /* flex-direction: row; */
    /* border: 2px solid yellow; */
    justify-content: space-evenly;
    padding-bottom: 200px;
}

.home-section-4 .image-div {
    /* border: 2px solid green; */
}

.home-section-4 .image-div .image {
    width: 30vw;
    max-width: 600px;
}

.home-section-4 .info-div .image {
    width: 25vw;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.home-section-4 .info-div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}


@media only screen and (max-width: 900px) {
    .home-section-4 .image-div .image {
        width: 50vw;
    }

    .home-section-4 .info-div .image {
        width: 60vw;
    }
}
.final-get-started {
    color: white;
    position: relative;
}

.final-get-started .get-started {
    margin-top: 50px;
    height: 50px;
    width: 200px;
}

.final-get-started .background {
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 500000px;
    left: -40000px;
    background: var(--homeBackgroundSecondary);
}

.final-get-started .content {
    padding-top: 100px;
    padding-bottom: 100px;
}
.home-footer {
    position: relative;
    color: white;
}

.home-footer .background {
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 500000px;
    left: -40000px;
    background: var(--homeFooter);
}

.home-footer .content {
    padding-top: 50px;
    padding-bottom: 200px;
}

.home-footer .footer-logo {
    width: 300px;
}



.splash {
    padding-bottom: 250px;
    color: white;
    /* border: 2px solid black; */
    /* position: relative; */
}

.splash .splash-content {
    /* border: 3px solid green; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 250px;
}

.splash .logo {
    position: absolute;
    width: 300px;
    left: 15px;
    top: 8px;
}

.splash .header {

}

.splash .subheader {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
}
.splash .subheader .MuiTypography-h3, .splash .subheader .MuiTypography-h5 {
    margin-right: 15px;
}

.splash .term {
    display: flex;
    flex-direction: row;
}

.splash .definition {
    margin-top: 18px;
}

.splash .definition-line2 {
    margin-left: 15px;
} 


.splash .get-started {
    color: white;
    height: 5.5vw;
    width: 21vw;
    max-height: 75px;
    max-width: 300px;
    display: block;
    margin-top: 50px;
    text-transform: none;
}

.splash .get-started .MuiButton-label {
    font-size: 18px;
    font-weight: 800;
}

.splash .info-div {
    position: relative;
    margin-left: -200px;
    margin-top: -100px;
    /* border: 3px solid blue; */
}

.splash .particles {
    width: 100%;
    height: 100%;
}

.splash .image-div {
    margin-top: -130px;
    display: flex;
    justify-content: center;
    /* border: 2px solid black; */
}

.splash .animation {
    /* border: 2px solid blue; */
}

.splash .image-div .image {
    width: 25vw;
    max-width: 300px;
    margin-top: auto;
    margin-bottom: auto;
}

.splash .login-buttons {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    right: 5px;
    z-index: 1000;
    color: white;
}

.splash .login-button {
    color: white;
}

.splash .pronunciation {
    margin-top: 20px;
}


@media only screen and (max-width: 900px) {
    .splash {
        padding-bottom: 220px;
    }
    .splash .logo {
        width: 34vw;
        min-width: 200px;
    }
    .splash .get-started {
        height: 45px;
        width: 200px;
    }
    .splash .get-started .MuiButton-label {
        padding-top: 0;
        font-size: 11px;
    }
    .splash .info-div {
        margin-left: 0;
        margin-top: -50px;
    }
    .splash .login-buttons {
        right: 0px;
    }
    .splash .definition {
        margin-top: 5px;
    }
    .splash .pronunciation {
        margin-top: 5px;
    }
    
}


@media only screen and (min-width: 2400px) {
    .splash {
        position: relative;
    }
}
.splash-circles {
    /* border: 2px solid red; */
    height: 100%;
}


.splash-circles div {
    border-radius: 50%;
}

.splash-circle1 {
    position: absolute;

    width: 250px;
    height: 250px;
    top: 600px;
    left: -80px;
    background: #FF7686;
    z-index: 2;
}

.splash-circle2 {
    position: absolute;

    width: 250px;
    height: 250px;
    top: 450px;
    left: 50%;
    background: #96DFFF;
    z-index: 2;
}

.splash-circle3 {
    position: absolute;

    width: 180px;
    height: 180px;
    top: 500px;
    left: calc(50% + 170px);
    background: #FF8181;
    z-index: 1;
}

.splash-circle4 {
    position: absolute;

    width: 370px;
    height: 370px;
    top: -120px;
    right: -120px;
    background: #39A0FF;
    z-index: 2;
}

@media only screen and (max-width: 900px) {
    .splash-circle1 {
        width: 150px;
        height: 150px;
        top: 530px;
        left: -60px;
    }

    .splash-circle2 {
        width: 150px;
        height: 150px;
        top: 450px;
        left: 50%;
    }

    .splash-circle3 {
        width: 100px;
        height: 100px;
        top: 480px;
        left: calc(50% + 100px);
    }

    .splash-circle4 {
        width: 270px;
        height: 270px;
        top: -120px;
        right: -130px;
    }
}
:root {
    --breakpointLarge: 1400px;
}
@media only screen and (min-width: 1400px) {
    div .MuiTypography-h1 {
        font-size: 75px;
    }
    div .MuiTypography-h2 {
        font-size: 55px;
    }
    div .MuiTypography-h3 {
        font-size: 45px;
    }

    div .MuiTypography-h4 {
        font-size: 25px;
    }

    div .MuiTypography-h5 {
        font-size: 20px;
    }
    div .MuiTypography-h6 {
        font-size: 16px;
    }
}

@media only screen and (max-width: 900px) {
    div .MuiTypography-h1 {
        font-size: 8vw;
    }
    div .MuiTypography-h2 {
        font-size: 7vw;
    }
    div .MuiTypography-h3 {
        font-size: 5.5vw;
    }

    div .MuiTypography-h4 {
        font-size: 5vw;
    }

    div .MuiTypography-h5 {
        font-size: 4vw;
    }
    div .MuiTypography-h6 {
        font-size: 3.5vw;
    }
    div .MuiTypography-body1 {
        font-size: 3.5vw;
    }
    div .MuiTypography-body2 {
        font-size: 3vw;
    }

    div .MuiButton-label {
        font-size: 10px;
        white-space: nowrap;
    }
}




/* auth card */

/* .fuzzi-card .container {
  background: var(--cardBackgroundPrimary);
  margin-right: 40px;
  overflow: hidden;
  position: relative;
} */

body {
  background: white;
}

.authForm .body {

}

.authForm .auth-content {
  max-width: 1440px;
  padding: 10px;
  padding-top: 40px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.authForm .invite-header {
  display: flex;
  flex-direction: row;
}

.authForm .invite-image-div {
  margin-top: 50px;
}

.authForm .invite-header .header {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
}

.authForm .auth-content .prof-pic {
  height: 80px;
  width: 80px;
  border-radius: 100px;
}

.authForm .background-image {
  border-radius: 46px;
  max-width: 500px;
  height: 550px;
  margin-bottom: 20px;
}

.login-component {
  width: 100%;
  max-width: 550px;
  /* padding-right: 250px; */
  background: white;
}


.login-component .container {
  margin: auto;
  margin-top: 50px;
  width: 100%;
}

.login-component .link {
  margin-top: 10px;
  display: block;
  padding-left: 0;
}

.login-component .link .MuiButton-label {
  text-decoration: underline;
  color: #8692A6;
}

.login-component .divider {
  height: 1px;
  width: 100%;
  background: #F5F5F5;
}

.login-component .submit {
  margin-top: 20px;
  height: 50px;
  width: 100%;
  border-radius: 4px !important;
}

.login-component .google-button {
  width: 100%;
  height: 50px;
  border-radius: 4px !important;
  background: white;
}

.login-component .google-icon {
  /* margin-right: 20px; */
  position: absolute;
  left: 70px;
}

.login-component .google-button .MuiButton-label {
  color: black;
}

.login-component .or {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* .AuthCard {
  width: 500px;
  margin: 10px auto;
  background: white;
  border-radius: 7px;
  box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.2);
  padding: 1em;
} */

.AuthCard h2 {
  color: black;
}

.AuthCard .isUser {
  margin-top: 5px;
}

.AuthCard .forgotPassword {
  margin-top: 10px;
}

/* auth form */

.authForm form {
  max-width: 500px;
  margin: 0 auto;
}

.authForm textarea,
.authForm input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid black;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

.authForm label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: black;
  font-size: 14px;
  font-weight: 200;
}


/* .authForm button[type="submit"] {
  top: calc(50% - 78.67px/2 + 47.33px);
  background: rgba(0, 0, 0, 0.81);
} */

/* .authForm button[type="submit"],
.authForm input[type="submit"] {
  background: var(--cardBackgroundPrimary);;
  color: black;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
} */

/* .authForm button[type="submit"]:hover,
.authForm input[type="submit"]:hover {
  background: #71A555;
} */

/* .authForm button[type="submit"]:active,
.authForm input[type="button"]:active,
.authForm input[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}  */

.authForm input:disabled {
  opacity: 0.4;
}

/* .authForm input[type="button"]:hover {
  transition: 0.3s all;
} */

/* .authForm button[type="submit"],
.authForm input[type="button"],
.authForm input[type="submit"] {
  -webkit-appearance: none;
} */


/* .authForm button[type="isUser"] {
  background: #989898;
  color: #FFFF;
  border-radius: 4px;
  border: 2.5px solid #71A667;
  padding: 10px 15px;
  margin-top: 5px;
  font-size: 14px;
} */

/* .authForm button[type="isUser"]:hover {
  background: #71A667;
}

.authForm button[type="isUser"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
} */

.authForm button[type="isUser"] {
  -webkit-appearance: none;
}


.authForm button[type="isGoogle"]::before {
    /* display: inline; */
    content: url(/static/media/google-logo.686f8efa.svg);
    /* display: block; */
    width: 18px;
    height: 18px;
    margin: -1px 0px 0px 0px;
    padding-left: 3px;
    padding-right: 40px;
    float: left;
    /* border: 1px solid white; */
}

.authForm button[type="isFacebook"] {
  height: 40px;
  width: 260px;
  background-color: rgba(66, 103, 178);
  color: #FFFF;
  border-radius: 4px;
  padding: 0px 15px;
  margin-top: 5px;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  line-height: 40px;
}


.authForm button[type="isFacebook"]:hover {
  background: rgba(9, 30, 66);
}

.authForm .facebook-login {
  height: 20px;
  width: 20px;
  margin: 8px 24px 0px 0px;
  /* padding-top: 4px; */
}

/* provider div styling*/

.authForm .provider-div {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;

}

/* .authForm button[type="forgotPassword"] {
  background: #989898;
  color: #FFFF;
  border-radius: 4px;
  border: 1px solid #989898;
  padding: 10px 15px;
  margin-top: 5px;
  font-size: 14px;
} */

/* .authForm button[type="forgotPassword"]:hover {
  background: #71A667;
}

.authForm button[type="forgotPassword"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
} */
/* 
.authForm button[type="forgotPassword"] {
  -webkit-appearance: none;
} */

/* .authForm button[type="svg-wrapper"] {
  height: 40px;
  width: 200px;
  position: absolute;
  
} */

@media only screen and (max-width: 900px) {
  .authForm .auth-content {
    flex-direction: column;
    justify-content: center;
  }
  .login-component .container {
    margin-top: 0px;
  }

  .authForm .background-image {
    margin-left: auto;
    margin-right: auto;
  }

  .login-component {
    margin-left: auto;
    margin-right: auto;
  }

  .authForm .background-image {
    width: 90vw;
    height: 93vw;
    max-width: 500px;
    max-height: 550px;
  }
}

  
  .error-msg .MuiTypography-body1::before {
    display: inline;
    content: "⚠ ";
  }


.app-bar {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 10000;
    background: var(--primary);
    width: 100vw;
}

.home-link {
  text-decoration: none;
}

.logo {
  display: flex;
  flex-direction: row;
}

.logo img {
  width: 300px;
}

/* MOBILE MEDIA */
@media only screen and (max-width: 1200px) {
  .logo img {
    width: 200px;
    height: 35px;
  }
}

.logo-text {
  padding-top: 7px;
}

  
  /* <ul> */
  .navbar-nav {
    width: 33%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* border: 1px solid pink; */
  }
  
  
  /* <li> */
  .navbar-nav li {
    display: flex;
    /* align-items: left; */
    vertical-align: top;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 10.5px;
    padding-top: 33.5px;
    padding-right: 23px;
  }
  
  .navbar-nav a {
    text-decoration: none;
    color: #808080;
    /* border: 1px solid black; */
  }
  
  .navbar-nav a:hover {
    color: #71A667;
  }
  
  .navbar-logo {
    width: 171px;
    height: 42.5px;
    padding-top: 5.6px;
    padding-left: 9px;
    /* border: 1px solid black; */
  }
  
  
  /* sign in */
  
  .navbar-nav-login {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    /* border: 1px solid black; */
  }
  
  /* <li> */
  .navbar-nav-login li {
    /* width: 50px; */
    display: flex;
    justify-content: flex-end;
    vertical-align: top;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 10.5px;
    padding-top: 21px;
    padding-right: 10px;
    padding-left: 10px;
    /* padding-bottom: 20px; */
  }
  
  .navbar-nav-login a {
    height: 10.5px;
    text-decoration: none;
    color: #808080;
    padding-top: 12.5px;
    /* border: 1px solid black; */
  }
  
  .navbar-nav-login a:hover {
    color: #0A0A;
  }
  
  /* get started nav button */
  .navbar-nav-login li[type="get-started"] {
    padding-top: 26.5px;
  
  }
  
  .navbar-nav-login a[type="get-started"] {
    width: 70px;
    height: 8px;
    background: #FFFF;
    color: #FFFF;
    border-radius: 20px;
    border: 2.5px solid #71A667;
    padding: 5px 15px;
    font-family: Roboto;
    font-style: normal;
    font-size: 10px;
    font-weight: 900;
    line-height: 10px;
    color: #71A667;
    flex-shrink: 0;
  }
  
  .navbar-nav-login a[type="get-started"]:active {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid transparent;
    opacity: 0.8;
  }
  
  .navbar-nav-login a[type="get-started"] {
    -webkit-appearance: none;
  }
  
  .navbar-nav .active-link {
    color: #71A667;
    font-weight: 500;
    letter-spacing: 0.028em;
    border-bottom: 1px solid #71A667;
  }
  
  .navbar-nav-login .active-link {
    color: #71A667;
    font-weight: 500;
    letter-spacing: 0.028em;
    border-bottom: 1px solid #71A667;
  }
  
.MuiButton-contained {
    color: white !important;
    border-radius: 100px !important;
    font-weight: 800 !important;
}

.MuiButtonBase-root {
    text-transform: none !important;
}


.dashboard {
    display: flex;
}

.custom-tab {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.custom-tab-text {
    padding-left: 25px;
    text-transform: none;
}

.custom-tab-icon {
    margin-left: 15px;
    width: 20px;
}

.divider {
    margin-top: 20px;
    margin-bottom: 20px;
}

.main {
    flex-grow: 1;
    padding-top: 70px;
}


/* MOBILE MEDIA */
@media only screen and (max-width: 1200px) {
    /* .MuiBox-root-14 {
        padding: 0 !important
    }
    .MuiBox-root-16 {
        padding: 0 !important
    } */
    .MuiBox-root {
        padding: 0 !important
    }
    .main {
        flex-grow: 1;
        padding-top: 60px;
    }
}
.profile {
    padding: 10px;
}

.profile .header {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
}

.profile .left {
    display: flex;
    flex-direction: row;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
}

.profile .prof-pic {
    position: relative;
    margin-top: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.profile .prof-pic .image {
    height: 200px;
    width: 200px;
    border-radius: 100px;
}

.profile .edit-pic {
    position: absolute;
    bottom: 5px;
    right: 10px;
    padding: 0;
}

.prof-pic .edit-pic-icon {
    width: 40px;
    height: 40px;
}

.profile .username {
    display: flex;
    flex-direction: row;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: auto;
    margin-left: 10px;
}

.profile .logout {
    margin-bottom: 20px;
}

.profile .logout-date {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: auto;
}

.profile .divider {
    width: 100%;
    height: 3px;
    background: black;
}

.profile .content {
    display: flex;
    flex-direction: column;
}


.image-preview {
    height: 200px;
    width: 200px;
    border-radius: 100px;
}

.profile .edit-name-div {
    display: flex;
    flex-direction: row;
}


/* MOBILE MEDIA */
@media only screen and (max-width: 1200px) {
    .profile .prof-pic {
        height: 60px;
    }

    .profile .prof-pic .image {
        height: 50px;
        width: 50px;
    }
    .profile .edit-pic {
        bottom: 0;
        right: 0px;
    }

    .prof-pic .edit-pic-icon {
        width: 20px;
        height: 20px;
    }
}
.textField {
    margin-bottom: 50px;
}
:root {
    --primary: #FF7079;
    --secondary: #70A9FF;
    --homeBackgroundSecondary: #FF9197;
    --cardBackgroundPrimary: #FFF3F3;
    --cardBackgroundSecondary: #FFC2C2;
    --drawerBackground: #363740;
    --textPrimary: #000000;
    --textSecondary: #A4A6B3;
    --homeGradient: linear-gradient(180deg, rgba(255, 112, 121, 0) 76.55%, #FFA9AE 95.88%), #FF7079;
    --homeFooter: #363740
}
.dashboard-appbar .fuzzis-logo {
    width: 300px;
}

.dashboard-appbar .fuzzis-logo-mobile {
    width: 150px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.dashboard-appbar .left-icon {
    color: white;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translate(0, -50%);
}

.dashboard-appbar .right-icon {
    color: white;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
}

.more-actions-dialog .list-item {
    
}

.dashboard-appbar .avatar {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    flex-direction: row;
}


.dashboard-appbar .avatar:hover {
    opacity: 0.8;
    cursor: pointer;
}
.dialog-link {
    font-weight: 800 !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.dialog-link-no-bold {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.dialog-link-no-bold:hover {
    cursor: pointer;
}

.dialog-link:hover {
    cursor: pointer;
}

.edit-button-label {
    opacity: 0.5;
    font-weight: 600 !important;
}

.dialog {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
}

.dialog .manager-dialog-button {

}
.jar-options {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.fuzzi-card-list .list {
    padding-top: 10px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: row;
    z-index: -10;
}


/* .fuzzi-card-list {
    overflow-x: scroll;
} */
.fuzzi-card .container {
    background: var(--cardBackgroundPrimary);
    margin-right: 40px;
    overflow: hidden;
    position: relative;
    /* width: 300px; */
}

.fuzzi-card .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
}

.fuzzi-card .title {
    padding: 2px;
    font-weight: 700;
}

.fuzzi-card .details {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-right: 10px;
    padding-left: 10px;
    /* border: 1px solid blue; */
}

.fuzzi-card .footer {
    background: var(--cardBackgroundSecondary);
    /* padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px; */
    height: 55px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fuzzi-card .footer-info {
    display: flex;
    flex-direction: row;
}

.fuzzi-card .info-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 10px;
}


.fuzzi-card .prof-pic {
    width: 45px;
    height: 45px;
    border-radius: 50px;
}

.fuzzi-card .jar {
    /* border: 1px solid black; */
    width: 115px; /* Size of container */
    height: 119px;
    overflow: hidden;
}

.fuzzi-card .jar img {
    margin: 0px 0px 0px -15px;
    width: 135px;
    height: 119px;
    /* width: 105px;
    height: 135px;  */
}

.fuzzi-card .header {
    display: flex;
    flex-direction: row;
    padding: 5px;
    border-bottom: 1px solid #FFC2C2
}

.fuzzi-card .authors-text {
    display: flex;
    flex-direction: row;
}

.fuzzi-card .authors-text .MuiTypography-body2 {
    margin-left: 3px;
}

.fuzzi-card .bold {
    font-weight: 800;
}

.fuzzi-card .author-prof-pic {
    width: 20px;
    height: 20px;
    border-radius: 10px;
}

.fuzzi-card .fraction {
    font-weight: 600;
}

.fuzzi-card .header div {
    white-space: nowrap;
}
.section-divider {
    height: 3px;
    background: black;
    width: 95%;
    margin-top: 20px;
    margin-bottom: 25px;
}

.my-fuzzi-section {
}
.jar-option {
    width: 25%;
    border-radius: 16px;
    margin-bottom: 20px;
    padding: 10px;
}

.jar-option .value-props {
    padding-top: 10px;
    padding-bottom: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
}

.jar-option .option-button {
    margin-top: 10px;
    width: 135px;
    height: 45px;
}

.fuzzi-jar-page {
    height: 100%;
}

.view-jar-page {
    padding: 24px;
}
