.home-section-3 {
    display: flex;
    /* border: 2px solid yellow; */
    width: 100%;
    justify-content: space-evenly;
    padding-top: 150px;
}

.home-section-3 .image-div {
    /* border: 2px solid green; */
    padding-top: 50px;
}

.home-section-3 .info-div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.home-section-3 .image-div .image {
    /* border: 5px solid orange; */
    width: 30vw;
    max-width: 550px;
    margin: auto;
}

.home-section-3 .info-div .image {
    width: 35vw;
    max-width: 600px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.home-section-3 .info-div .text {
    margin-top: 10px;
    margin-bottom: 10px;
}


@media only screen and (max-width: 900px) {
    .home-section-3 .image-div .image {
        width: 50vw;
    }

    .home-section-3 .info-div .image {
        width: 60vw;
    }

    .home-section-3 {
        padding-top: 100px;
    }
}