.fuzzi-card .container {
    background: var(--cardBackgroundPrimary);
    margin-right: 40px;
    overflow: hidden;
    position: relative;
    /* width: 300px; */
}

.fuzzi-card .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
}

.fuzzi-card .title {
    padding: 2px;
    font-weight: 700;
}

.fuzzi-card .details {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-right: 10px;
    padding-left: 10px;
    /* border: 1px solid blue; */
}

.fuzzi-card .footer {
    background: var(--cardBackgroundSecondary);
    /* padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px; */
    height: 55px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fuzzi-card .footer-info {
    display: flex;
    flex-direction: row;
}

.fuzzi-card .info-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 10px;
}


.fuzzi-card .prof-pic {
    width: 45px;
    height: 45px;
    border-radius: 50px;
}

.fuzzi-card .jar {
    /* border: 1px solid black; */
    width: 115px; /* Size of container */
    height: 119px;
    overflow: hidden;
}

.fuzzi-card .jar img {
    margin: 0px 0px 0px -15px;
    width: 135px;
    height: 119px;
    /* width: 105px;
    height: 135px;  */
}

.fuzzi-card .header {
    display: flex;
    flex-direction: row;
    padding: 5px;
    border-bottom: 1px solid #FFC2C2
}

.fuzzi-card .authors-text {
    display: flex;
    flex-direction: row;
}

.fuzzi-card .authors-text .MuiTypography-body2 {
    margin-left: 3px;
}

.fuzzi-card .bold {
    font-weight: 800;
}

.fuzzi-card .author-prof-pic {
    width: 20px;
    height: 20px;
    border-radius: 10px;
}

.fuzzi-card .fraction {
    font-weight: 600;
}

.fuzzi-card .header div {
    white-space: nowrap;
}