.splash-circles {
    /* border: 2px solid red; */
    height: 100%;
}


.splash-circles div {
    border-radius: 50%;
}

.splash-circle1 {
    position: absolute;

    width: 250px;
    height: 250px;
    top: 600px;
    left: -80px;
    background: #FF7686;
    z-index: 2;
}

.splash-circle2 {
    position: absolute;

    width: 250px;
    height: 250px;
    top: 450px;
    left: 50%;
    background: #96DFFF;
    z-index: 2;
}

.splash-circle3 {
    position: absolute;

    width: 180px;
    height: 180px;
    top: 500px;
    left: calc(50% + 170px);
    background: #FF8181;
    z-index: 1;
}

.splash-circle4 {
    position: absolute;

    width: 370px;
    height: 370px;
    top: -120px;
    right: -120px;
    background: #39A0FF;
    z-index: 2;
}

@media only screen and (max-width: 900px) {
    .splash-circle1 {
        width: 150px;
        height: 150px;
        top: 530px;
        left: -60px;
    }

    .splash-circle2 {
        width: 150px;
        height: 150px;
        top: 450px;
        left: 50%;
    }

    .splash-circle3 {
        width: 100px;
        height: 100px;
        top: 480px;
        left: calc(50% + 100px);
    }

    .splash-circle4 {
        width: 270px;
        height: 270px;
        top: -120px;
        right: -130px;
    }
}