.home-section-1 {
    display: flex;
    /* flex-direction: row; */
    /* border: 2px solid yellow; */
    justify-content: space-evenly;
    padding-bottom: 50px;
}

.home-section-1 .image-div {
    /* border: 2px solid green; */
}

.home-section-1 .image-div .image {
    width: 30vw;
    max-width: 500px;
}

.home-section-1 .info-div .image {
    width: 40vw;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.home-section-1 .info-div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}


@media only screen and (max-width: 900px) {
    .home-section-1 .image-div .image {
        width: 50vw;
    }

    .home-section-1 .info-div .image {
        width: 60vw;
    }
}