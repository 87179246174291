.dashboard {
    display: flex;
}

.custom-tab {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.custom-tab-text {
    padding-left: 25px;
    text-transform: none;
}

.custom-tab-icon {
    margin-left: 15px;
    width: 20px;
}

.divider {
    margin-top: 20px;
    margin-bottom: 20px;
}

.main {
    flex-grow: 1;
    padding-top: 70px;
}


/* MOBILE MEDIA */
@media only screen and (max-width: 1200px) {
    /* .MuiBox-root-14 {
        padding: 0 !important
    }
    .MuiBox-root-16 {
        padding: 0 !important
    } */
    .MuiBox-root {
        padding: 0 !important
    }
    .main {
        flex-grow: 1;
        padding-top: 60px;
    }
}