.dialog-link {
    font-weight: 800 !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.dialog-link-no-bold {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.dialog-link-no-bold:hover {
    cursor: pointer;
}

.dialog-link:hover {
    cursor: pointer;
}

.edit-button-label {
    opacity: 0.5;
    font-weight: 600 !important;
}

.dialog {
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
}

.dialog .manager-dialog-button {

}