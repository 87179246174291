
  body {
    /* overflow-x: hidden; */
  }

.home {
  position: relative;
  overflow-x: hidden;
}

.home .particles {
  position: absolute;
  width: 1400px;
  height: 800px;
}



.home .gradient-angle {
  background: var(--homeGradient);
  height: 2000px;
  width: 140%;
  position: absolute;
  transform: rotate(-10deg);
  left: -20%;
  top: -1400px;
  z-index: -1;
  margin: auto;
}

.home .content {
  max-width: 1400px;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
}


@media only screen and (max-width: 900px) {
  .home .gradient-angle {
    width: 200%;
    top: -1400px;
    left: -100%;
  }
}

