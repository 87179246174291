.dashboard-appbar .fuzzis-logo {
    width: 300px;
}

.dashboard-appbar .fuzzis-logo-mobile {
    width: 150px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.dashboard-appbar .left-icon {
    color: white;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translate(0, -50%);
}

.dashboard-appbar .right-icon {
    color: white;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
}

.more-actions-dialog .list-item {
    
}

.dashboard-appbar .avatar {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    flex-direction: row;
}


.dashboard-appbar .avatar:hover {
    opacity: 0.8;
    cursor: pointer;
}