.fuzzi-animation {
    background: rgba(0,0,0,0.9);
    z-index: 10000;

    /* covers full screen */
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    /* border: 3px solid yellow; */
    overflow: scroll;

    /* contained to card */
    /* position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; */

}

.fuzzi-animation .animated-fuzzi {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
}

.fuzzi-animation .particles {
    /* position: absolute; */
    height: 100vh;
    width: 100vw;
}

.fuzzi-animation .animation {
    /* position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0); */
}

.fuzzi-animation .particle-grow {
    height: 100vh;
    width: 100vw;
    /* border: 3px solid blue */
}

.fuzzi-animation .fuzzi-grow {
    position: relative;
    margin: auto;
    min-width: 40vw;
    /* border: 3px solid green; */
}

.fuzzi-animation .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border: 3px solid red; */
}

.fuzzi-animation .confirm-fuzzi {
    height: 80px;
    width: 250px;
    margin-top: 20px;
    /* margin-left: auto;
    margin-right: auto; */
}

.fuzzi-animation .call-to-action {
    margin-top: 20px;
}

/* MOBILE MEDIA */
@media only screen and (max-width: 1200px) {
    .fuzzi-animation .fuzzi-grow {
        min-width: 80vw;
    }
    .fuzzi-animation .confirm-fuzzi {
        height: 40px;
        width: 180px;
    }
}