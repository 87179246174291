


.splash {
    padding-bottom: 250px;
    color: white;
    /* border: 2px solid black; */
    /* position: relative; */
}

.splash .splash-content {
    /* border: 3px solid green; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 250px;
}

.splash .logo {
    position: absolute;
    width: 300px;
    left: 15px;
    top: 8px;
}

.splash .header {

}

.splash .subheader {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
}
.splash .subheader .MuiTypography-h3, .splash .subheader .MuiTypography-h5 {
    margin-right: 15px;
}

.splash .term {
    display: flex;
    flex-direction: row;
}

.splash .definition {
    margin-top: 18px;
}

.splash .definition-line2 {
    margin-left: 15px;
} 


.splash .get-started {
    color: white;
    height: 5.5vw;
    width: 21vw;
    max-height: 75px;
    max-width: 300px;
    display: block;
    margin-top: 50px;
    text-transform: none;
}

.splash .get-started .MuiButton-label {
    font-size: 18px;
    font-weight: 800;
}

.splash .info-div {
    position: relative;
    margin-left: -200px;
    margin-top: -100px;
    /* border: 3px solid blue; */
}

.splash .particles {
    width: 100%;
    height: 100%;
}

.splash .image-div {
    margin-top: -130px;
    display: flex;
    justify-content: center;
    /* border: 2px solid black; */
}

.splash .animation {
    /* border: 2px solid blue; */
}

.splash .image-div .image {
    width: 25vw;
    max-width: 300px;
    margin-top: auto;
    margin-bottom: auto;
}

.splash .login-buttons {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    right: 5px;
    z-index: 1000;
    color: white;
}

.splash .login-button {
    color: white;
}

.splash .pronunciation {
    margin-top: 20px;
}


@media only screen and (max-width: 900px) {
    .splash {
        padding-bottom: 220px;
    }
    .splash .logo {
        width: 34vw;
        min-width: 200px;
    }
    .splash .get-started {
        height: 45px;
        width: 200px;
    }
    .splash .get-started .MuiButton-label {
        padding-top: 0;
        font-size: 11px;
    }
    .splash .info-div {
        margin-left: 0;
        margin-top: -50px;
    }
    .splash .login-buttons {
        right: 0px;
    }
    .splash .definition {
        margin-top: 5px;
    }
    .splash .pronunciation {
        margin-top: 5px;
    }
    
}


@media only screen and (min-width: 2400px) {
    .splash {
        position: relative;
    }
}