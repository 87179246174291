:root {
    --primary: #FF7079;
    --secondary: #70A9FF;
    --homeBackgroundSecondary: #FF9197;
    --cardBackgroundPrimary: #FFF3F3;
    --cardBackgroundSecondary: #FFC2C2;
    --drawerBackground: #363740;
    --textPrimary: #000000;
    --textSecondary: #A4A6B3;
    --homeGradient: linear-gradient(180deg, rgba(255, 112, 121, 0) 76.55%, #FFA9AE 95.88%), #FF7079;
    --homeFooter: #363740
}