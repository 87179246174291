/* auth card */

/* .fuzzi-card .container {
  background: var(--cardBackgroundPrimary);
  margin-right: 40px;
  overflow: hidden;
  position: relative;
} */

body {
  background: white;
}

.authForm .body {

}

.authForm .auth-content {
  max-width: 1440px;
  padding: 10px;
  padding-top: 40px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.authForm .invite-header {
  display: flex;
  flex-direction: row;
}

.authForm .invite-image-div {
  margin-top: 50px;
}

.authForm .invite-header .header {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
}

.authForm .auth-content .prof-pic {
  height: 80px;
  width: 80px;
  border-radius: 100px;
}

.authForm .background-image {
  border-radius: 46px;
  max-width: 500px;
  height: 550px;
  margin-bottom: 20px;
}

.login-component {
  width: 100%;
  max-width: 550px;
  /* padding-right: 250px; */
  background: white;
}


.login-component .container {
  margin: auto;
  margin-top: 50px;
  width: 100%;
}

.login-component .link {
  margin-top: 10px;
  display: block;
  padding-left: 0;
}

.login-component .link .MuiButton-label {
  text-decoration: underline;
  color: #8692A6;
}

.login-component .divider {
  height: 1px;
  width: 100%;
  background: #F5F5F5;
}

.login-component .submit {
  margin-top: 20px;
  height: 50px;
  width: 100%;
  border-radius: 4px !important;
}

.login-component .google-button {
  width: 100%;
  height: 50px;
  border-radius: 4px !important;
  background: white;
}

.login-component .google-icon {
  /* margin-right: 20px; */
  position: absolute;
  left: 70px;
}

.login-component .google-button .MuiButton-label {
  color: black;
}

.login-component .or {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* .AuthCard {
  width: 500px;
  margin: 10px auto;
  background: white;
  border-radius: 7px;
  box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.2);
  padding: 1em;
} */

.AuthCard h2 {
  color: black;
}

.AuthCard .isUser {
  margin-top: 5px;
}

.AuthCard .forgotPassword {
  margin-top: 10px;
}

/* auth form */

.authForm form {
  max-width: 500px;
  margin: 0 auto;
}

.authForm textarea,
.authForm input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid black;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

.authForm label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: black;
  font-size: 14px;
  font-weight: 200;
}


/* .authForm button[type="submit"] {
  top: calc(50% - 78.67px/2 + 47.33px);
  background: rgba(0, 0, 0, 0.81);
} */

/* .authForm button[type="submit"],
.authForm input[type="submit"] {
  background: var(--cardBackgroundPrimary);;
  color: black;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
} */

/* .authForm button[type="submit"]:hover,
.authForm input[type="submit"]:hover {
  background: #71A555;
} */

/* .authForm button[type="submit"]:active,
.authForm input[type="button"]:active,
.authForm input[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}  */

.authForm input:disabled {
  opacity: 0.4;
}

/* .authForm input[type="button"]:hover {
  transition: 0.3s all;
} */

/* .authForm button[type="submit"],
.authForm input[type="button"],
.authForm input[type="submit"] {
  -webkit-appearance: none;
} */


/* .authForm button[type="isUser"] {
  background: #989898;
  color: #FFFF;
  border-radius: 4px;
  border: 2.5px solid #71A667;
  padding: 10px 15px;
  margin-top: 5px;
  font-size: 14px;
} */

/* .authForm button[type="isUser"]:hover {
  background: #71A667;
}

.authForm button[type="isUser"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
} */

.authForm button[type="isUser"] {
  -webkit-appearance: none;
}


.authForm button[type="isGoogle"]::before {
    /* display: inline; */
    content: url(../../assets/google-logo.svg);
    /* display: block; */
    width: 18px;
    height: 18px;
    margin: -1px 0px 0px 0px;
    padding-left: 3px;
    padding-right: 40px;
    float: left;
    /* border: 1px solid white; */
}

.authForm button[type="isFacebook"] {
  height: 40px;
  width: 260px;
  background-color: rgba(66, 103, 178);
  color: #FFFF;
  border-radius: 4px;
  padding: 0px 15px;
  margin-top: 5px;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  line-height: 40px;
}


.authForm button[type="isFacebook"]:hover {
  background: rgba(9, 30, 66);
}

.authForm .facebook-login {
  height: 20px;
  width: 20px;
  margin: 8px 24px 0px 0px;
  /* padding-top: 4px; */
}

/* provider div styling*/

.authForm .provider-div {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;

}

/* .authForm button[type="forgotPassword"] {
  background: #989898;
  color: #FFFF;
  border-radius: 4px;
  border: 1px solid #989898;
  padding: 10px 15px;
  margin-top: 5px;
  font-size: 14px;
} */

/* .authForm button[type="forgotPassword"]:hover {
  background: #71A667;
}

.authForm button[type="forgotPassword"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
} */
/* 
.authForm button[type="forgotPassword"] {
  -webkit-appearance: none;
} */

/* .authForm button[type="svg-wrapper"] {
  height: 40px;
  width: 200px;
  position: absolute;
  
} */

@media only screen and (max-width: 900px) {
  .authForm .auth-content {
    flex-direction: column;
    justify-content: center;
  }
  .login-component .container {
    margin-top: 0px;
  }

  .authForm .background-image {
    margin-left: auto;
    margin-right: auto;
  }

  .login-component {
    margin-left: auto;
    margin-right: auto;
  }

  .authForm .background-image {
    width: 90vw;
    height: 93vw;
    max-width: 500px;
    max-height: 550px;
  }
}