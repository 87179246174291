.final-get-started {
    color: white;
    position: relative;
}

.final-get-started .get-started {
    margin-top: 50px;
    height: 50px;
    width: 200px;
}

.final-get-started .background {
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 500000px;
    left: -40000px;
    background: var(--homeBackgroundSecondary);
}

.final-get-started .content {
    padding-top: 100px;
    padding-bottom: 100px;
}