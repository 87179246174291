.home-footer {
    position: relative;
    color: white;
}

.home-footer .background {
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 500000px;
    left: -40000px;
    background: var(--homeFooter);
}

.home-footer .content {
    padding-top: 50px;
    padding-bottom: 200px;
}

.home-footer .footer-logo {
    width: 300px;
}