.jar-option {
    width: 25%;
    border-radius: 16px;
    margin-bottom: 20px;
    padding: 10px;
}

.jar-option .value-props {
    padding-top: 10px;
    padding-bottom: 10px;
    width: fit-content;
    margin: auto;
}

.jar-option .option-button {
    margin-top: 10px;
    width: 135px;
    height: 45px;
}