

.app-bar {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 10000;
    background: var(--primary);
    width: 100vw;
}

.home-link {
  text-decoration: none;
}

.logo {
  display: flex;
  flex-direction: row;
}

.logo img {
  width: 300px;
}

/* MOBILE MEDIA */
@media only screen and (max-width: 1200px) {
  .logo img {
    width: 200px;
    height: 35px;
  }
}

.logo-text {
  padding-top: 7px;
}

  
  /* <ul> */
  .navbar-nav {
    width: 33%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* border: 1px solid pink; */
  }
  
  
  /* <li> */
  .navbar-nav li {
    display: flex;
    /* align-items: left; */
    vertical-align: top;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 10.5px;
    padding-top: 33.5px;
    padding-right: 23px;
  }
  
  .navbar-nav a {
    text-decoration: none;
    color: #808080;
    /* border: 1px solid black; */
  }
  
  .navbar-nav a:hover {
    color: #71A667;
  }
  
  .navbar-logo {
    width: 171px;
    height: 42.5px;
    padding-top: 5.6px;
    padding-left: 9px;
    /* border: 1px solid black; */
  }
  
  
  /* sign in */
  
  .navbar-nav-login {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    /* border: 1px solid black; */
  }
  
  /* <li> */
  .navbar-nav-login li {
    /* width: 50px; */
    display: flex;
    justify-content: flex-end;
    vertical-align: top;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 10.5px;
    padding-top: 21px;
    padding-right: 10px;
    padding-left: 10px;
    /* padding-bottom: 20px; */
  }
  
  .navbar-nav-login a {
    height: 10.5px;
    text-decoration: none;
    color: #808080;
    padding-top: 12.5px;
    /* border: 1px solid black; */
  }
  
  .navbar-nav-login a:hover {
    color: #0A0A;
  }
  
  /* get started nav button */
  .navbar-nav-login li[type="get-started"] {
    padding-top: 26.5px;
  
  }
  
  .navbar-nav-login a[type="get-started"] {
    width: 70px;
    height: 8px;
    background: #FFFF;
    color: #FFFF;
    border-radius: 20px;
    border: 2.5px solid #71A667;
    padding: 5px 15px;
    font-family: Roboto;
    font-style: normal;
    font-size: 10px;
    font-weight: 900;
    line-height: 10px;
    color: #71A667;
    flex-shrink: 0;
  }
  
  .navbar-nav-login a[type="get-started"]:active {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid transparent;
    opacity: 0.8;
  }
  
  .navbar-nav-login a[type="get-started"] {
    -webkit-appearance: none;
  }
  
  .navbar-nav .active-link {
    color: #71A667;
    font-weight: 500;
    letter-spacing: 0.028em;
    border-bottom: 1px solid #71A667;
  }
  
  .navbar-nav-login .active-link {
    color: #71A667;
    font-weight: 500;
    letter-spacing: 0.028em;
    border-bottom: 1px solid #71A667;
  }
  