.home-section-2 {
    position: relative;
}


.home-section-2 .background {
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 500000px;
    left: -40000px;
    background: var(--homeBackgroundSecondary);
}