.fuzzi-jar-manager {
    height: 100%;
}

.fuzzi-jar-manager .container {
    background: var(--cardBackgroundPrimary);
    position: relative;
    height: calc(100vh - 120px);
    overflow: hidden;
}

.fuzzi-jar-manager .back-button {
    position: absolute;
    left: 0;
    top: 0;
}


.fuzzi-jar-manager .content {
    width: 90%;
    max-height: 85%;
    margin: auto;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
}

.fuzzi-jar-manager .title-div {
    flex-grow: 0;
}

.fuzzi-jar-manager .title-flex {
    display: flex;
    flex-direction: row;
}

.fuzzi-jar-manager .divider {
    height: 3px;
    background: black;
}

.fuzzi-jar-manager .footer {
    background: var(--cardBackgroundSecondary);
    height: 80px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fuzzi-jar-manager .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
}

.fuzzi-jar-manager .delete {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px;
}

.fuzzi-jar-manager .jar {
    height: 100px;
}

.fuzzi-jar-manager .fuzzi-button {
    width: 175px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
}

.fuzzi-jar-manager .info-div {
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    width: fit-content;
    justify-content: space-evenly;
    position: absolute;
    right: 0;
    top: 20px
}

.fuzzi-jar-manager .users-info {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.fuzzi-jar-manager .prof-pic {
    height: 50px;
    border-radius: 50px;
    margin-right: 10px;
}

.fuzzi-jar-manager .recipient {
    display: flex;
    flex-direction: row;
}

.fuzzi-jar-manager .vertical-divider {
    width: 1px;
    height: 40px;
    background: black;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    margin-right: 10px;
}

.fuzzi-jar-manager .collaborators {
    display: flex;
    flex-direction: row;
}   

.fuzzi-jar-manager .finish-and-send {
    height: 50px;
    width: 150px;
}



.fuzzi-jar-manager .messages {
    flex-grow: 2;
    overflow-y: scroll;
    padding-top: 10px;
    padding-bottom: 20px;
}


.fuzzi-jar-manager .footer-text {
    margin-top: auto !important;
    margin-bottom: auto;
}


/* MOBILE */

.fuzzi-jar-manager-mobile .container {
    background: var(--cardBackgroundPrimary);
    height: calc(100vh - 60px);
    width: 99vw;
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.fuzzi-jar-manager-mobile .header {
    padding: 10px;
    border-bottom: 2px solid black;
    flex-grow: 0;
}

.fuzzi-jar-manager-mobile .subheader {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.fuzzi-jar-manager-mobile .subheader {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 10px;
}

.fuzzi-jar-manager-mobile .subheader-info {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.fuzzi-jar-manager-mobile .fuzzis-opened {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fuzzi-jar-manager-mobile .jar {
    height: 80px;
}

.fuzzi-jar-manager-mobile .fuzzi-button {
    width: 125px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
}

.is-jar-manager-add-to-jar {
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
}

.fuzzi-jar-manager-mobile .content {
    padding: 20px;
    /* background: blue; */
    flex-grow: 2;
    overflow-y: scroll;
}

.fuzzi-jar-manager-mobile .footer {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    background: var(--cardBackgroundSecondary);
    width: 100%;
    min-height: 45px;
    display: flex;
    flex-direction: row;
    /* border: 2px solid green; */
}

.fuzzi-jar-manager-mobile .recipient {
    display: flex;
    flex-direction: row;
}

.fuzzi-jar-manager-mobile .vertical-divider {
    width: 1px;
    height: 30px;
    background: black;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    margin-right: 10px;
}

.fuzzi-jar-manager-mobile .prof-pic {
    margin-top: auto;
    margin-bottom: auto;
    height: 40px;
    width: 40px;
    margin-right: 5px;
    border-radius: 50px;
}