.profile {
    padding: 10px;
}

.profile .header {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
}

.profile .left {
    display: flex;
    flex-direction: row;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
}

.profile .prof-pic {
    position: relative;
    margin-top: auto;
    height: fit-content;
}

.profile .prof-pic .image {
    height: 200px;
    width: 200px;
    border-radius: 100px;
}

.profile .edit-pic {
    position: absolute;
    bottom: 5px;
    right: 10px;
    padding: 0;
}

.prof-pic .edit-pic-icon {
    width: 40px;
    height: 40px;
}

.profile .username {
    display: flex;
    flex-direction: row;
    height: fit-content;
    margin-top: auto;
    margin-left: 10px;
}

.profile .logout {
    margin-bottom: 20px;
}

.profile .logout-date {
    height: fit-content;
    margin-top: auto;
}

.profile .divider {
    width: 100%;
    height: 3px;
    background: black;
}

.profile .content {
    display: flex;
    flex-direction: column;
}


.image-preview {
    height: 200px;
    width: 200px;
    border-radius: 100px;
}

.profile .edit-name-div {
    display: flex;
    flex-direction: row;
}


/* MOBILE MEDIA */
@media only screen and (max-width: 1200px) {
    .profile .prof-pic {
        height: 60px;
    }

    .profile .prof-pic .image {
        height: 50px;
        width: 50px;
    }
    .profile .edit-pic {
        bottom: 0;
        right: 0px;
    }

    .prof-pic .edit-pic-icon {
        width: 20px;
        height: 20px;
    }
}